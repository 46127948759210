import Client from 'config/axios';

export interface IBodyLinked {
  token: string;
  followers: number;
  photo: string;
  uid: number;
  secret: string;
  verifier?: string;
  screenName: string;
  code?: string;
  facebookProfileUrl?: string;
  authenticationCode?: string;
}

export const postNetworkToken = async (
  token: string,
  tokenNetwork: IBodyLinked,
  network: string
) => {
  return await Client.post(`linked-social-network/${network}`, tokenNetwork, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response)
    .catch(error => {
      throw error;
    });
};

export const unlinkedNetwork = async (token: string, network: string) => {
  try {
    const { data } = await Client.delete(`unlinked-social-network/${network}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch (response) {
    const result = response as any;
    return { errors: result.data };
  }
};
