import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import { DescriptionHeader } from '../../CampaignDetailPage/CampaignHeader/Style';
import {
  StyledHorizontalBox,
  HeaderHorizontalBox
} from '../../../components/boxes/HorizontalBox/StyledHorizontalBox';
import {
  StyledPoints,
  PointsGrid,
  BoxAction,
  ImagePreviewButton,
  ImagePreview
} from '../../../components/boxes/PostPointsBox/StyledPostPointsBox';
import moment from 'moment';
import 'moment/locale/es';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import Button from 'components/buttons/Button';
import {
  validFbUrl,
  validTwUrl,
  validLkUrl,
  validIgUrl,
  validTkUrl,
  validSnapUrl,
  validYtUrl,
  validThUrl,
  validOthersUrl
} from 'helpers/socialUrlValidate';
import { createBonus } from 'services/rest/Bonus/createBonus';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { useParams } from 'react-router-dom';
import { DivIconChallenge } from '../AmbassadorChallenges/Styled';
import { SocialButtton, PostDetailWrapper } from './Styled';
import { Colors } from 'styles/Constants';

interface detailChallengeProps {
  challenge: any;
}

const DetailChallengeAmbassador: React.FC<detailChallengeProps> = props => {
  const { challenge } = props;
  const { currentUser } = useSelector(selectUser);
  const { challengeId } = useParams<any>();
  const [isOpen, setIsOpen] = useState(false);
  const mediaUrl = challenge?.multimedia?.picturesUrls?.[0] || '';

  const { t } = useTranslation([
    'challenge',
    'campaigns_page',
    'campaign_section'
  ]);
  const socialNetworks = [
    'facebook',
    'twitter',
    'linkedin',
    'instagram',
    'tiktok',
    'snapchat',
    'youtube',
    'threads',
    'others'
  ];

  const timeLine = (startDate: any, finishDate: any) => {
    startDate = moment(startDate);
    const startMonth = startDate.format('MMM');
    const startDay = startDate.format('D');
    finishDate = moment(finishDate);
    const finishMonth = finishDate.format('MMM');
    const finishDay = finishDate.format('D');
    return `${startMonth} ${startDay} - ${finishMonth} ${finishDay}`;
  };

  const showPostMessage = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const [selectedIcon, setSelectedIcon] = useState(null);
  const [socialNetwork, setSocialNetwork] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [pendingPostMessage, setPendingPostMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const urlPost = document.getElementById('url-input') as any;
  const handleIconClick = (icon: any, socialNetwork: any) => {
    setSelectedIcon(icon);
    setSocialNetwork(socialNetwork);
    setErrorMessage(false);
  };
  const handleCreateBonus = async (
    token: any,
    url: any,
    socialNetwork: any,
    challengeId: any
  ) => {
    const { data, errors } = await createBonus(
      token,
      url,
      socialNetwork,
      parseInt(challengeId)
    );
    if (data) {
      setPendingPostMessage(true);
      setLoader(false);
    }
    if (errors) {
      setLoader(false);
    }
  };

  const onPublish = async () => {
    setLoader(true);
    if (socialNetwork === 'facebook' && validFbUrl(urlPost.value)) {
      handleCreateBonus(
        currentUser?.token,
        urlPost.value,
        socialNetwork,
        parseInt(challengeId)
      );
    } else if (socialNetwork === 'twitter' && validTwUrl(urlPost.value)) {
      handleCreateBonus(
        currentUser?.token,
        urlPost.value,
        socialNetwork,
        parseInt(challengeId)
      );
    } else if (socialNetwork === 'linkedin' && validLkUrl(urlPost.value)) {
      handleCreateBonus(
        currentUser?.token,
        urlPost.value,
        socialNetwork,
        parseInt(challengeId)
      );
    } else if (socialNetwork === 'instagram' && validIgUrl(urlPost.value)) {
      handleCreateBonus(
        currentUser?.token,
        urlPost.value,
        socialNetwork,
        parseInt(challengeId)
      );
    } else if (socialNetwork === 'tiktok' && validTkUrl(urlPost.value)) {
      handleCreateBonus(
        currentUser?.token,
        urlPost.value,
        socialNetwork,
        parseInt(challengeId)
      );
    } else if (socialNetwork === 'snapchat' && validSnapUrl(urlPost.value)) {
      handleCreateBonus(
        currentUser?.token,
        urlPost.value,
        socialNetwork,
        parseInt(challengeId)
      );
    } else if (socialNetwork === 'youtube' && validYtUrl(urlPost.value)) {
      handleCreateBonus(
        currentUser?.token,
        urlPost.value,
        socialNetwork,
        parseInt(challengeId)
      );
    } else if (socialNetwork === 'threads' && validThUrl(urlPost.value)) {
      handleCreateBonus(
        currentUser?.token,
        urlPost.value,
        socialNetwork,
        parseInt(challengeId)
      );
    } else if (socialNetwork === 'otros' && validOthersUrl(urlPost.value)) {
      handleCreateBonus(
        currentUser?.token,
        urlPost.value,
        socialNetwork,
        parseInt(challengeId)
      );
    } else {
      setErrorMessage(true);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="grid-block">
        <DescriptionHeader className="span-sm-12 span-md-7">
          <h1 className="challenge-detail-name">{challenge?.name}</h1>
          <span className="challenge-detail-dates">
            <Icon size="small" name="calendar-o" />
            {timeLine(challenge?.startDate, challenge?.finishDate)}
          </span>
        </DescriptionHeader>
      </div>

      <h1 className="section-title">{t('challenge:publish_content')}</h1>

      <StyledHorizontalBox size="normal" type="socialNetwork">
        <HeaderHorizontalBox
          size="normal"
          backgroundImage={challenge?.photo}
          className="img-box"
        ></HeaderHorizontalBox>

        <StyledPoints>
          <PointsGrid>
            <h2>
              {t('challenge:rules')}
              <Icon name="star" color="#000" />
            </h2>

            <p>{challenge?.content}</p>
            <h2>{t('challenge:social_network_tips')}</h2>

            <DivIconChallenge className="m-auto">
              {socialNetworks.map(network => {
                return (
                  challenge[network] && (
                    <SocialMediaIcon
                      key={network}
                      name={network === 'others' ? 'otros' : network}
                      size="medium"
                      shape="round"
                    />
                  )
                );
              })}
            </DivIconChallenge>
            <h2>{t('challenge:format')}</h2>
            <p>
              {challenge.format === 'video, image'
                ? `${t('challenge:image')} ${t('challenge:and')} ${t(
                    'challenge:video'
                  )}`
                : `${t(`challenge:${challenge.format}`)}`}
            </p>
            {mediaUrl && (
              <ImagePreview>
                <h2>{t('challenge:multimedia')}</h2>
                <ImagePreviewButton
                  onClick={() => {
                    const newWindow = window.open(
                      mediaUrl,
                      '_blank',
                      'noopener,noreferrer'
                    );
                    if (newWindow) newWindow.opener = null;
                  }}
                >
                  {t('challenge:See_multimedia')}
                </ImagePreviewButton>
              </ImagePreview>
            )}

            {challenge?.multimedia?.videoUrl && (
              <ImagePreview>
                <h2>{t('challenge:multimedia')}</h2>
                <ImagePreviewButton
                  onClick={() => {
                    if (challenge.multimedia.videoUrl) {
                      const newWindow = window.open(
                        challenge.multimedia.videoUrl,
                        '_blank',
                        'noopener,noreferrer'
                      );
                      if (newWindow) newWindow.opener = null;
                    }
                  }}
                  aria-label={t('challenge:See_multimedia')}
                >
                  {t('challenge:See_multimedia')}
                </ImagePreviewButton>
              </ImagePreview>
            )}
          </PointsGrid>

          <BoxAction>
            {!isOpen && (
              <Button
                value={t('challenge:share_content')}
                variant="secondary"
                onClick={showPostMessage}
                as="a"
                size="big"
              />
            )}
            {pendingPostMessage && (
              <PostDetailWrapper>
                <p className="small-paragraph">
                  {t('campaign_section:created_bonus')}
                </p>
                <Button
                  value={t('campaign_section:participate_again')}
                  variant="success"
                  as="a"
                  size="medium"
                  target="_blank"
                  onClick={() => {
                    setIsOpen(true);
                    setPendingPostMessage(false);
                    setSelectedIcon(null);
                    setSocialNetwork(null);
                  }}
                />
              </PostDetailWrapper>
            )}
            {isOpen && !pendingPostMessage && (
              <>
                <div>
                  <div>
                    <p>{t('campaign_section:text_modal')}</p>
                    <span>
                      <b>{t('campaign_section:warning_text')}</b>
                    </span>
                  </div>
                  <div className="network">
                    <p>{t('campaign_section:select_social_network')}</p>
                    <div>
                      <SocialButtton
                        className={selectedIcon === 'icon1' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon1', 'facebook')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="facebook"
                          color={Colors.facebook}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon2' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon2', 'twitter')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="twitter"
                          color={Colors.twitter}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon3' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon3', 'linkedin')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="linkedin"
                          color={Colors.linkedin}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon4' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon4', 'instagram')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="instagram"
                          color={Colors.instagram}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon5' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon5', 'tiktok')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="tiktok"
                          color={Colors.tiktok}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon6' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon6', 'snapchat')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="snapchat"
                          color={Colors.snapchat}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon7' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon7', 'youtube')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="youtube"
                          color={Colors.youtube}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon8' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon8', 'threads')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="threads"
                          color={Colors.threads}
                        />
                      </SocialButtton>
                      <SocialButtton
                        className={selectedIcon === 'icon9' ? 'selected' : ''}
                        onClick={() => handleIconClick('icon9', 'otros')}
                      >
                        <SocialMediaIcon
                          size="small"
                          name="otros"
                          color={Colors.otros}
                        />
                      </SocialButtton>
                    </div>
                  </div>
                  <p>Post URL:</p>
                  <input
                    id="url-input"
                    type="url"
                    className="bonus-box"
                    placeholder={t('campaign_section:post_url')}
                  />
                </div>
                {errorMessage && (
                  <p className="error-message-challenge">
                    {t('campaign_section:error_message')}
                  </p>
                )}
                <Button
                  value={t('campaign_section:send')}
                  variant="tertiary"
                  onClick={onPublish}
                  loader={loader}
                />
              </>
            )}
          </BoxAction>
        </StyledPoints>
      </StyledHorizontalBox>
    </>
  );
};

export default DetailChallengeAmbassador;
