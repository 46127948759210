import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Facebook as LoadingState } from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import { selectUser } from 'redux/slices/userSlice';
import {
  Igroup,
  IGroupAmssador
} from 'redux/slices/GroupAmbassador/GroupAmbassador.interface';
import {
  selectGroupAmbassador,
  setData,
  setLoadingGroupAmbassador
} from 'redux/slices/GroupAmbassador/GroupAmbassadorSlice';
import { getGroupAmbassador } from 'services/rest/GetGroupAmbassador/GetGroupAmbassador';
import { RequiredString } from 'helpers/yupHelper';
import { sortGroups } from 'helpers/groupsHelper';
import {
  awsUploadFileHelper,
  setFileNameHelper
} from 'helpers/awsCampaignFileHelper';
import { generateRandAlphaNumStr } from 'Utils/StringRandom';
import { getUrlS3 } from 'constants/setup.constants';
import { ErrorAlert } from 'components/Alert';
import { SuccessAlert } from 'components/Alert';
import CreateChallengeForm from './CreateChallengeForm';
import { createChallenge } from 'services/rest/Challenge/createChallenge';
import { awsUploadVideoHelper } from 'helpers/awsVideoHelper';

const CreateChallengePage: React.FC = () => {
  const { t } = useTranslation(['challenge', 'validations']);
  const dispatch = useDispatch();
  const userSchema = yup.object().shape({
    name: RequiredString().max(
      150,
      t('validations:length_error', { max: 150 })
    ),
    content: RequiredString().max(
      3000,
      t('validations:length_error', { max: 3000 })
    )
  });

  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(userSchema)
  });

  useEffect(() => {
    dispatch(setLoadingGroupAmbassador(true));
    getGroup('');
  }, []);
  const [backendError, setBackendError] = useState<string | null>(null);
  const [errorSocialNetwork, setErrorSocialNetwork] = useState<string>('');
  const [errorFormat, setErrorFormat] = useState<string>('');
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [hourt, setHourt] = useState<any>('00');
  const [minutes, setMinutes] = useState<any>('00');

  const [image, setImage] = useState<Array<any>>([]);
  const [checkMultimedia, setCheckMultimedia] = useState<boolean>(true);
  const [multimediaType, setMultimediaType] = useState<string>('image');
  const [multimediaContent, setMultimediaContent] = useState<Array<any>>([]);
  const [uploadedMultimedia, setUploadedMultimedia] = useState<boolean | null>(
    null
  );
  const [group, setGroup] = useState<Array<number>>([]);
  const [ambassadors, setAmbassadors] = useState<Array<number>>([]);
  const [backendSuccess, setBackendSuccess] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const { currentUser } = useSelector(selectUser);

  const { groupsAmbassador = [], loading } = useSelector(selectGroupAmbassador);

  const handleClick = (
    startDate: Date,
    finishDate: Date,
    hour: any,
    minutes: any
  ) => {
    const dateStartString = `${startDate.getFullYear()}/${
      startDate.getMonth() + 1
    }/${startDate.getDate()} ${hour}:${minutes}:00`;

    const dateStart = new Date(dateStartString);

    const dateFinishString = `${finishDate.getFullYear()}/${
      finishDate.getMonth() + 1
    }/${finishDate.getDate()} 22:59:59`;

    const dateFinish = new Date(dateFinishString);

    setStartDate(dateStart);
    setFinishDate(dateFinish);
  };

  const handleImagen = (select: Array<any>, content: string) => {
    switch (content) {
      case 'photo':
        setImage(select);
        break;
      case 'multimedia':
        setMultimediaContent(select);
        break;
    }
  };

  const handleParticipation = (
    group: Array<Igroup>,
    ambassadors: Array<Igroup>
  ) => {
    const groupsIds = group.map(select => select.id);
    const ambassadorsIds = ambassadors.map(select => select.id);

    setGroup(groupsIds);
    setAmbassadors(ambassadorsIds);
  };

  const getGroup = async (name: string) => {
    await getGroupAmbassador(currentUser?.token, 1, 500, name)
      .then(response => {
        const group = sortGroups(response.data.data.groups.docs);
        const ambassadors = sortGroups(response.data.data.ambassadors.docs);
        const groupAmbassador: IGroupAmssador = {
          groups: group,
          ambassador: ambassadors
        };
        dispatch(setData(groupAmbassador));
        dispatch(setLoadingGroupAmbassador(false));
      })
      .catch(error => {
        dispatch(setLoadingGroupAmbassador(false));
      });
  };

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const onSubmit = async (challengeData: any) => {
    setLoader(true);
    if (image.length === 0) {
      setBackendError(t('challenge:image_required'));
      setLoader(false);
      return;
    }

    if (group.length == 0 && ambassadors.length == 0) {
      setBackendError(t('challenge:error_info_ambassador'));
      setLoader(false);
      return;
    }
    if (
      !challengeData.facebook &&
      !challengeData.twitter &&
      !challengeData.linkedin &&
      !challengeData.instagram &&
      !challengeData.tiktok &&
      !challengeData.snapchat &&
      !challengeData.youtube &&
      !challengeData.threads &&
      !challengeData.others
    ) {
      setErrorSocialNetwork(t('challenge:error_social_network'));
      setBackendError(t('challenge:error_social_network'));
      setLoader(false);
      return;
    }

    if (!challengeData.formatImage && !challengeData.formatVideo) {
      setErrorFormat(t('challenge:error_format'));
      setBackendError(t('challenge:error_format'));
      setLoader(false);
      return;
    }

    if (checkMultimedia && multimediaContent.length == 0) {
      setBackendError(t('challenge:error_multimedia'));
      setLoader(false);
      return;
    }

    if (image) {
      const namePhoto = generateRandAlphaNumStr();
      const uploadedPhoto = await awsUploadFileHelper(
        currentUser?.token,
        image,
        true,
        'CHALLENGE_IMG',
        namePhoto,
        t,
        setBackendError
      );
      if (uploadedPhoto) {
        challengeData.photo = getUrlS3('challenges', namePhoto);
      } else {
        setBackendError(t('validations:file_error'));
        setLoader(false);
        return;
      }
    }

    let multimedia: any;
    if (checkMultimedia) {
      if (multimediaType === 'image') {
        const multimediaFile = [];
        if (multimediaContent) {
          setUploadedMultimedia(false);
        }
        for (const file of multimediaContent) {
          if (!file?.file?.type) {
            const name = file.url.split('/').reverse()[0];
            multimediaFile.push({ name });
            continue;
          }
          const nameMultimedia = setFileNameHelper(file, false);
          await awsUploadFileHelper(
            currentUser?.token,
            file,
            false,
            'CHALLENGE_IMG',
            nameMultimedia,
            t,
            setBackendError
          );
          multimediaFile.push({ name: nameMultimedia });
        }
        multimedia = {
          picturesUrls: multimediaFile.map((file: any) =>
            getUrlS3('challenges', file?.name)
          )
        };
      }
      if (multimediaType === 'video') {
        setUploadedMultimedia(false);
        const nameMultimedia = setFileNameHelper(multimediaContent, false);
        const upload = await awsUploadVideoHelper(
          currentUser?.token,
          multimediaContent,
          'CHALLENGE_IMG',
          nameMultimedia,
          t,
          setBackendError
        );
        if (upload) {
          setUploadedMultimedia(true);
          multimedia = {
            videoUrl: getUrlS3('challenges', nameMultimedia)
          };
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    } else {
      multimedia = {
        picturesUrls: [],
        videoUrl: null
      };
    }

    challengeData.startDate = new Date(
      `${startDate.getFullYear()}/${
        startDate.getMonth() + 1
      }/${startDate.getDate()} ${hourt}:${minutes}`
    ).toString();
    challengeData.finishDate = new Date(
      `${finishDate.getFullYear()}/${
        finishDate.getMonth() + 1
      }/${finishDate.getDate()} 22:59:59`
    ).toString();

    challengeData.companyId = currentUser.user.companyId;
    challengeData.adminId = currentUser.user.id;
    challengeData.groupsIds = group;
    challengeData.ambassadorsIds = ambassadors;
    challengeData.status = 'pending';
    challengeData.format =
      challengeData.formatImage && challengeData.formatVideo
        ? 'video, image'
        : challengeData.formatImage
        ? 'image'
        : challengeData.formatVideo
        ? 'video'
        : null;
    challengeData.multimedia = multimedia;

    const { data, errors } = await createChallenge(
      currentUser?.token,
      challengeData
    );

    if (data) {
      history.push('/creators', t('challenge:success_message'));
    }

    if (errors) {
      setBackendError(errors.data);
      return;
    }

    setLoader(false);
  };

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}

      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      <CreateChallengeForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        setValue={setValue}
        handleClick={handleClick}
        groupsAmbassador={groupsAmbassador}
        getData={getGroup}
        handleParticipation={handleParticipation}
        handleImage={handleImagen}
        errorSocialNetwork={errorSocialNetwork}
        errorFormat={errorFormat}
        loader={loader}
        setHourt={setHourt}
        setMinutes={setMinutes}
        sHour={hourt}
        sMinutes={minutes}
        checkMultimedia={checkMultimedia}
        setCheckMultimedia={setCheckMultimedia}
        multimediaType={multimediaType}
        setMultimediaType={setMultimediaType}
        uploadedMultimedia={uploadedMultimedia}
      />
    </>
  );
};

export default CreateChallengePage;
