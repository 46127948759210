import React, { useEffect, useState } from 'react';

import Icon from 'components/icons/Icon';
import Input from 'components/inputs/Input';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { generateRandAlphaNumStr } from 'Utils/StringRandom';

import LinearProgress from 'components/LinearProgress/LinearProgress';

import {
  ErrorSpan,
  MarginPreview,
  MarginUploadFile,
  CloseButton,
  StatusSuccess
} from './StyleUploadFile';
import StatusIcon from 'components/icons/StatusIcon';

interface IUploadFileProps {
  icon?: string;
  name?: string;
  handleVideo?: any;
  id?: string;
  className?: string;
  content?: string;
  allowedFileTypes?: any;
  videosArray?: Array<any>;
  maxNumberOfFiles?: number;
  maxFileSize?: number;
  maxFileDuration?: number;
  ref?: any;
  infoBox?: string;
  disabled?: boolean;
  uploaded?: boolean | null;
}

const UploadFileVideo: React.FC<IUploadFileProps> = props => {
  const typeFile = 'video/mp4';
  const {
    icon,
    name,
    handleVideo,
    id,
    className,
    content,
    allowedFileTypes = typeFile,
    videosArray = [],
    maxFileSize = 100000,
    maxFileDuration = 140,
    ref,
    infoBox,
    disabled = false,
    uploaded
  } = props;

  const { t } = useTranslation(['campaigns_page']);
  const [videos, setVideos] = useState<any>(null);
  const [errorSize, setErrorSize] = useState<boolean>(false);
  const [errorMB, setErrorMB] = useState<boolean>(false);
  const [errorTime, setErrorTime] = useState<boolean>(false);
  const [errorFileType, setErrorFileType] = useState<boolean>(false);

  const media = videosArray?.map(video => {
    return {
      index: generateRandAlphaNumStr(),
      name: '',
      url: video
    };
  });

  useEffect(() => {
    if (media?.length > 0) {
      setVideos(media[0]);
    }
  }, []);

  const changeInput = (e: any) => {
    setErrorSize(false);
    setErrorMB(false);
    setErrorTime(false);
    setErrorFileType(false);
    const sizekiloBytes = parseInt(e?.currentTarget?.files[0]?.size) / 1024;
    const size = parseInt(sizekiloBytes.toString());

    if (e?.currentTarget?.files[0]?.type !== typeFile) {
      setErrorFileType(true);
      return;
    }

    if (size > maxFileSize) {
      setErrorMB(true);
      return;
    }

    if (content === 'twitter') {
      const video = document.createElement('video');
      video.addEventListener('loadedmetadata', event => {
        if (video.videoWidth > 1920 && video.videoHeight > 1920) {
          deleteVideo();
          setErrorSize(true);
          return;
        }
      });
      video.src = URL.createObjectURL(e?.currentTarget?.files[0]);
    }

    const newVideoToState = readFile(e);
    setVideos(newVideoToState);
    handleVideo(newVideoToState, content);
  };

  function readFile(e: any) {
    const file = e.currentTarget.files[0];
    const url = URL.createObjectURL(file);

    const objectVideo = {
      index: generateRandAlphaNumStr(),
      name: file.name,
      url: url,
      file
    };

    return objectVideo;
  }

  const onVideoLoad = ({ target: video }: any) => {
    const { naturalHeight, naturalWidth } = video;
    if (naturalWidth > 1920 && naturalHeight > 1920) {
      deleteVideo();
      setErrorSize(true);
      return;
    }
  };

  const deleteVideo = () => {
    handleVideo([], content);
    setVideos(null);
  };

  const validationDelete = (onDuration: number) => {
    if (onDuration > maxFileDuration) {
      deleteVideo();
      setErrorTime(true);
      return;
    }
  };

  const size = maxFileSize / 1000;
  const time = `${maxFileDuration} seg`;

  return (
    <>
      {!videos ? (
        <MarginUploadFile id={id} className={className}>
          <>
            <Icon name={icon ? icon : 'file'} size="big" color="#979797" />
            <h3>{t('attach_video')}</h3>
            <p>{infoBox}</p>
            <Input
              type="file"
              accept={allowedFileTypes}
              onChange={changeInput}
              name={name}
              ref={ref}
            />
          </>
        </MarginUploadFile>
      ) : (
        <MarginPreview
          id={id}
          className={`${name ? `${className}-active` : 'Empty'}`}
        >
          <>
            <ReactPlayer
              controls
              width={'200px'}
              height={'200px'}
              url={videos.url}
              onDuration={(e: number) => validationDelete(e)}
              onLoad={(e: any) => onVideoLoad(e)}
            />

            {uploaded === true && (
              <StatusSuccess>
                <StatusIcon variant={'success'} />
              </StatusSuccess>
            )}
            {!disabled && uploaded === null && (
              <CloseButton>
                <Icon
                  size="small"
                  name="close-rounded"
                  onClick={() => deleteVideo()}
                  color="rgba(0,0,0,0.54)"
                />
              </CloseButton>
            )}
          </>
          {uploaded === false && <LinearProgress />}
        </MarginPreview>
      )}
      {errorSize && <ErrorSpan>{t('error_resolution_video')}</ErrorSpan>}
      {errorMB && <ErrorSpan>{t('error_weight_video', { size })}</ErrorSpan>}
      {errorTime && <ErrorSpan>{t('error_time_video', { time })}</ErrorSpan>}
      {errorFileType && <ErrorSpan>{t('error_type_file_video')}</ErrorSpan>}
    </>
  );
};

export default UploadFileVideo;
