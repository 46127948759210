import iconCheck from 'images/icons/check.svg';
import iconCheckInactive from 'images/icons/checkbox_inactive_label.svg';
import DropdownDisable from 'images/icons/dropdown-disable.svg';
import styled, { css } from 'styled-components';
import { Breakpoints, Colors } from 'styles/Constants';

export interface SectionProps {
  disabled?: boolean;
}

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 9fr 3fr;
`;

export const NetworkFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items; center;
    input{
      margin: 0 20px;
    }
`;

export const LabelForm = styled.div`
  grid-template-columns: 190px 1fr;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  display: grid;
  width: 100%;

  &.multimedia {
    grid-template-columns: 190px 160px 1fr;
    margin-bottom: 40px;

    .marginFile {
      margin-top: 0;
      position: relative;
      margin-left: 0%;

      .socialNetworkFile {
        height: 125px !important;
        width: 100% !important;
        border-radius: 12px 12px 0px 0px !important;
      }

      .videoChallenge-active {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px !important;
      }
    }
  }

  input {
    transition: all 0.5s ease-out 0s;
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
    border: 1px solid #979797;
    font-size: 0.8125rem;
    color: ${Colors.secondaryNormalGrey};
    border-radius: 10px;
    padding: 10px 15px;
    font-weight: 400;
    display: block;
    width: 100%;
    outline: none;
    @media (max-width: ${Breakpoints.large}) {
      max-width: 80%;
    }
    @media (max-width: ${Breakpoints.xxLarge}) {
      max-width: 85%;
    }
  }

  div.select {
    width: 85px;
    border-radius: 30px !important;
    height: 40px;
    border-color: #979797;
    color: ${Colors.secondaryNormalGrey};
    font-weight: 500;
    font-size: 14px;
  }

  input.input-search {
    background-image: url(${DropdownDisable});
    transition: all 0.1s ease-out 0s;
    background-repeat: no-repeat;
    background-position: 90% 50%;
    background-size: 22px;
    padding-right: 50px;
    outline: none;
    height: 40px;
    width: 300px;
  }

  div.search-containe {
    position: absolute;
    background-color: #ffffff;
    z-index: 1;
    box-shadow: 0 1px 12px 0 rgb(0 0 0 / 20%);
    margin: 10px 0 0 10px;
    padding-right: 20px;
    width: 300px;
    input {
      position: relative;
      user-select: none;
      cursor: pointer;
      display: block;
      outline: none;
      height: 15px;
      width: 15px;
      padding: 7px;
      margin-right: 20px;
    }
  }

  div input.input-search.selected {
    border: 1px solid #004fea;
    color: #004fea;
  }

  input.input-search.selected::placeholder {
    color: #004fea;
    font-size: 13px;
    font-weight: 500;
  }

  div.search-containe .search-result .checkbok-wrapper:hover {
    background-color: #cecece;
  }

  div.search-containe .search-result .checkbok-wrapper .icon-Group {
    fill: #757575;
    width: 13px;
    height: 13px;
    margin-right: 20px;
  }

  div.search-containe .search-result .checkbok-wrapper label {
    color: #333333;
    width: 150px;
    margin-top: -1px;
    margin-bottom: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: 13px;
    cursor: pointer;
    text-transform: capitalize;
  }

  div.search-containe
    .search-result
    .checkbok-wrapper
    input[type='checkbox']:checked
    ~ label {
    color: #004fea;
    text-transform: capitalize;
  }

  div.search-result {
    overflow: auto;
    height: 250px;
    border: none;
    padding: 0px;
    width: 100%;
  }

  div.checkbok-wrapper {
    justify-content: flex-start;
    border-bottom: 1px solid rgba(216, 216, 216, 0.3);
    cursor: pointer;
    margin: 2px 2px;
    padding: 10px;
    display: flex;
  }

  div.network {
    display: flex;
    padding: 20px 0;

    &.border-b {
      border-bottom: 1px solid #979797;
    }

    @media (max-width: ${Breakpoints.small}) {
      display: -webkit-box;
    }

    .network-wrapper {
      display: flex;
      padding: 10px 0;
    }

    input {
      width: 5%;
      appearance: none;
      visibility: visible;
      background: #d8d8d8;
      position: relative;
      border-radius: 3em;
      cursor: pointer;
      outline: none;
      border: none;
      height: 20px;
      width: 35px;
    }
    input:checked {
      background: #7ed321;
    }
    input:after {
      left: calc(100% - 1.5em);
      background: #a5adba url(${iconCheckInactive});
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
      transition: all 0.2s ease-in-out;
      -webkit-transform: scale(0.92);
      transform: scale(0.92);
      position: absolute;
      border-radius: 50%;
      height: 22px;
      width: 22px;
      content: '';
      top: -1px;
      left: -5px;
    }
    input:checked:after {
      left: calc(100% - 1.5em);
      background: #92e239 url(${iconCheck});
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
      transition: all 0.2s ease-in-out;
      transform: scale(0.92);
      position: absolute;
      border-radius: 50%;
      height: 22px;
      width: 22px;
    }
  }

  textarea {
    transition: all 0.5s ease-out 0s;
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
    border: 1px solid #979797;
    font-size: 0.8125rem;
    color: ${Colors.secondaryNormalGrey};
    border-radius: 10px;
    padding: 10px 15px;
    font-weight: 400;
    display: block;
    width: 100%;
    outline: none;
    @media (max-width: ${Breakpoints.large}) {
      max-width: 80%;
    }
    @media (max-width: ${Breakpoints.xxLarge}) {
      max-width: 85%;
    }
  }

  label {
    color: ${Colors.secondaryNormalGrey};
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }

  p {
    font-size: 12px;
    line-height: 1.42857;
    color: #333333;
    margin-right: 10px;
  }

  .marginText {
    margin-top: -120%;
  }

  .marginColumn {
    margin-top: 2%;
    margin-bottom: 5%;
  }

  .marginLabel {
    margin-block-end: auto;
  }

  .column-2 {
    display: flex;

    label {
      margin-right: 70px;
      margin-top: 10px;
    }

    input {
      width: 7%;
      text-align: center;
    }
  }

  .borde {
    margin-top: 40px;
    border-bottom: 1px solid #979797;
  }

  .positionIcon {
    position: relative;
    left: 20px;
    bottom: 30px;
    margin-right: 12px;
  }

  .marginFile {
    margin-top: 13%;
    position: absolute;
    margin-left: 70%;

    @media (min-width: ${Breakpoints.cLarge}) and (max-width: ${Breakpoints.cxLarge}) {
      margin-left: 80%;
      margin-top: 25%;
    }
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  .styled_border {
    height: 30px;
    width: 100%;
    border: 1px solid #979797;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-bottom: 20px;
    padding: 5px 10px;
    svg {
      height: 20px;
      width: 20px;
      margin: 0 10px;
      cursor: pointer;
      fill: #00a9f0;
    }

    svg.icon-noactive {
      fill: #ade7fd;
      cursor: pointer;
    }

    fill: #ade7fd;
  }
`;

export const ErrorSpan = styled.span`
  padding-left: 10px;
  font-weight: 500;
  font-size: 13px;
  grid-column: 2;
  color: #ff303f;
`;

export const GridComponent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const FlexSection = styled.div<SectionProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.7;
    `};
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    margin-left: 10px;
  }

  svg {
    margin-right: 10px;
    @media (max-width: ${Breakpoints.small}) {
      max-width: 80%;
      margin-right: 10px;
    }
  }

  #spanPoint {
    font-size: 25px;
    margin-left: 8px;
    margin-right: 8px;
    font-weight: 900;
  }

  div.dropdown-filter-content__control {
    width: 100px;
    border: 1px solid #979797;
    border-radius: 30px;
    background-color: transparent;
    box-shadow: none;
    height: 30px;

    &:hover,
    &:focus-within {
      border-color: ${Colors.primaryDarkBlue};
      cursor: pointer;
    }
  }

  div.dropdown-filter-content__single-value {
    color: #3e66fe;
    margin-left: 50%;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
  }

  div.dropdown-filter-content__value-container {
    padding: 0;
  }

  span.dropdown-filter-content__indicator-separator {
    display: none;
  }

  div.dropdown-filter-content__indicator {
    color: rgba(0, 0, 0, 0.54);
  }

  div.dropdown-filter-content__placeholder {
    padding-bottom: 15px;
    font-size: 15px;
    color: ${Colors.inactive};
  }

  div.dropdown-filter-content__menu {
    margin-top: 1px;
    width: 170px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    z-index: 5;
  }

  div.dropdown-filter-content__menu-list {
    padding-top: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  div.dropdown-filter-content__option {
    padding: 6px 8px;
    font-size: 14px;
  }

  div.dropdown-filter-content__option--is-focused {
    background: transparent;
  }

  div.dropdown-filter-content__option:hover,
  div.dropdown-filter-content__option--is-focused:hover,
  div.dropdown-filter-content__option--is-selected {
    background: rgba(0, 0, 0, 0.08);
    font-weight: 500;
    color: #3e66fe;
  }
`;

export const TimeZoneCampaign = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  label {
    font-size: 11px;
    font-weight: 500;
  }
`;

export const MarginAmbassadorPage = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 60%;
    font-size: 1.125rem;
    height: 20%;
    margin-bottom: 30px;
    border: 1px solid #d113fe;
    background-color: #d113fe;
  }
`;

export const MarginButton = styled.div`
  button {
    text-align: center;
    margin: auto;
    display: flex;
    @media (max-width: ${Breakpoints.small}) {
      margin-top: 20px;
    }
  }
`;
